.circular--portrait {
    position: relative;
    width: 68px;
    height: 68px;
    overflow: hidden;
    border-radius: 50%;
  }
  
  .circular--portrait img {
    width: 100%;
    height: auto;
  }


  /*
[1]: All items a forced onto a single line, causing the overflow when necessary.
[2]: Automatic overflow means a scroll bar won’t be present if it isn’t needed
[3]: Make it smooth scrolling on iOS devices before
[4]: Hide the ugly scrollbars in Edge until the scrollable area is hovered
[5]: Hide the scroll bar in WebKit browsers
*/
  .scroll {
    white-space: nowrap; /* [1] */
    overflow-x: auto; /* [2] */
    -webkit-overflow-scrolling: touch; /* [3] */
    -ms-overflow-style: -ms-autohiding-scrollbar; /* [4] */ 
    padding-bottom: 20px;
  }
  
  /* [5] */
  .scroll::-webkit-scrollbar {
    display: none; 
  }

    .scroll a
    {
      text-decoration:none;      
      text-align:center; 
      padding-right:20px;
      color: rgb(2, 0, 8);
      font-weight: 600;
/*
      border:1px solid gray;      
      background-color:silver;
      font-weight:bold;      
      width:80px;
     */
    }

    .product-row{
      padding-top: 20px;
    }

    .priceActive{
      font-weight: 600;
    }